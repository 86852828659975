import styled from "styled-components"

export const ModalMenu = styled.div`
  position: fixed;
  z-index: 1000000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--modal-menu-overlay);
  padding: 8px;

  ul {
    background: var(--modal-menu-bg);
    box-shadow: 0px 2px 4px rgba(33, 37, 75, 0.4);
    border-radius: 6px;
    width: calc(100% - 8px);
    list-style-type: none;
    padding: 0;
  }

  li {
    button {
      padding: 0 8px;
      height: 36px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      font-weight: 600;
      font-size: 15px;
      line-height: 28px;
      background: var(--modal-menu-item);
      color: var(--modal-menu-color);
    }

    :hover {
      background: var(--modal-menu-item-hover);
    }
  }
`
