import React from "react"
import { createPortal } from "react-dom"
import { PureButton } from "uikit/Button"
import * as S from "./styled"

const ModalMenu = ({ items, isOpen, onClose, onSelect }) => {
  const handleSelect = item => {
    onSelect(item)
    onClose()
  }

  if (!isOpen || typeof document === `undefined`) {
    return null
  }

  return createPortal(
    <S.ModalMenu onClick={onClose}>
      <ul role="presentation" onClick={e => e.stopPropagation()}>
        {items.map(item => (
          <li key={item.name}>
            <PureButton onClick={() => handleSelect(item)}>{item.name}</PureButton>
          </li>
        ))}
      </ul>
    </S.ModalMenu>,
    document.body
  )
}

export default ModalMenu
