import styled, { css } from "styled-components"
import CodeBlock from "components/CodeBlock"

export const Container = styled.div`
  background: #2e3361;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

export const FilesWrap = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 56px;
  display: flex;
  padding: 16px 16px 10px 10px;
  align-items: flex-start;
  flex-shrink: 0;

  button {
    padding-bottom: 8px;
  }
`

export const Files = styled.ul`
  margin: 0;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
`

export const fileActive = css`
  color: #fff;
  svg {
    fill: #fff;
  }
`

export const fileHover = css`
  color: #d9dae5;
  svg {
    fill: #d9dae5;
  }
`

export const File = styled.li`
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a1a1bd;
  font-size: 13px;
  font-weight: 400;
  margin: 0 10px 8px 10px;
  min-height: 24px;

  svg {
    margin-right: 6px;
    fill: #a1a1bd;
  }

  &:hover {
    ${({ isActive }) => !isActive && fileHover}
  }

  ${({ isActive }) => isActive && fileActive}
`

export const Code = styled(CodeBlock)`
  overflow-y: auto;
  background: #2e3361;
  padding: 24px 0;
  margin: 0;
  height: 100%;
  box-sizing: border-box;

  tr {
    height: 20px;
  }

  * {
    font-size: 13px;
    background: #2e3361;
  }
`

export const ModalContainer = styled.div`
  z-index: 10000;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  height: 100%;

  display: ${p => (p.isOpen ? "block" : "none")};

  > div {
    border-radius: 0;
    height: 100%;
    width: 100vw;
  }
`
