import styled from "styled-components"

export const Container = styled.div`
  max-width: 1720px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
`

export const Main = styled.main`
  width: 40%;
  @media (max-width: 800px) {
    width: 100%;
  }
`

export const Aside = styled.aside`
  width: calc(60% - 40px);
  margin-left: 40px;
  position: relative;

  @media (max-width: 800px) {
    width: 0;
    margin-left: 0;
  }

  > div {
    top: 80px;
    position: sticky;
    height: calc(100vh - 120px);
    color: #fff;
  }
`
