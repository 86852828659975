import styled from "styled-components"
import { Link as GLink } from "gatsby"

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`

export const InnerLink = styled(GLink)`
  font-size: 13px;
  line-height: 24px;
  color: var(--tutorial-back);
  text-decoration: none;

  display: flex;
  align-items: center;
  transition: 0.3s;

  :hover {
    color: var(--tutorial-back-hover);

    svg {
      path:first-child {
        stroke: var(--tutorial-back-hover);
      }

      path:last-child {
        fill: var(--tutorial-back-hover);
      }
    }
  }

  svg {
    margin-right: 2px;

    path {
      transition: 0.3s;
    }
  }
`

export const Link = styled.a`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  color: var(--tutorial-link);
  text-decoration: none;
  transition: 0.3s;

  svg {
    margin-left: 8px;

    path {
      transition: 0.3s;
      fill: var(--tutorial-link);
    }
  }

  :hover {
    color: var(--tutorial-link-hover);

    svg {
      path {
        fill: var(--tutorial-link-hover);
      }
    }
  }
`
