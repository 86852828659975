import React from "react"
import { graphql } from "gatsby"

import useMenu from "hooks/useMenu"
import { getMeta } from "../getMeta"
import Layout from "./Layout"

const Tutorial = ({ data, location, pageContext: { files } }) => {
  const menu = useMenu(data.page.frontmatter.id)
  const meta = getMeta(data.page.frontmatter)

  return (
    <Layout
      files={files}
      title={data.page.frontmatter.title}
      date={data.page.fields?.modifiedTime}
      content={data.page.body}
      location={location}
      menu={menu}
      meta={meta}
    />
  )
}

export const query = graphql`
  query($slug: String) {
    page: mdx(slug: { eq: $slug }) {
      body
      fields {
        modifiedTime(formatString: "DD MMMM YYYY")
      }
      frontmatter {
        metaTitle
        metaKeywords
        metaDescription
        title
        id
      }
    }
  }
`

export default Tutorial
