import React, { useRef } from "react"

import useBreakpoint from "hooks/useBreakpoint"
import TitleBox from "components/TitleBox"
import Markdown from "components/Markdown"
import Common from "components/Common"
import useFindText from "hooks/useFindText"
import CodeTutorial from "../CodeTutorial"
import Navigation from "../Navigation"

import useTutorialController from "./useTutorialController"
import * as S from "./styled"

const Layout = ({ location, title, date, content, menu, files, meta }) => {
  const ref = useRef()
  const highlights = useTutorialController(ref)
  const findTextProps = useFindText(location)
  const [isTablet, isMobile] = useBreakpoint([800, 600])

  return (
    <Common isMobileSidebar location={location} meta={meta} menu={menu} {...findTextProps}>
      <S.Container id="wrapper">
        <S.Main ref={ref}>
          <Navigation backUrl={menu.article.parent.path} />
          <TitleBox title={title} date={date} />
          <Markdown location={location?.hash} content={content} {...findTextProps} />
        </S.Main>
        <S.Aside>
          <CodeTutorial
            isModalFilesSelect={isMobile}
            isModal={isTablet}
            files={files}
            highlights={highlights}
          />
        </S.Aside>
      </S.Container>
    </Common>
  )
}

export default Layout
