import { useState, useEffect } from "react"

const useScrollController = area => {
  const [highlights, setHighlights] = useState({})

  useEffect(() => {
    const container = area.current
    const cards = [...document.querySelectorAll("[data-card]")]

    const getHighlights = el => ({
      file: el.dataset.file,
      lines: el.dataset.lines ? JSON.parse(el.dataset.lines) : [],
    })

    const setActiveCard = active => {
      cards.forEach(el => el.classList.remove("active"))

      if (active) {
        const hg = getHighlights(active)
        setHighlights(prevValue => (hg.lines.length === 0 ? prevValue : hg))
        active.classList.add("active")
      }
    }

    cards.forEach(el => {
      el.addEventListener("mouseenter", () => window.innerWidth > 800 && setActiveCard(el))
      el.addEventListener("mouseleave", () => window.innerWidth > 800 && setActiveCard(null))
      el.addEventListener("click", () => window.innerWidth < 800 && setActiveCard(el))
    })

    const codeTutorialEl = document.getElementById("code-tutorial")
    const handleClickOutside = e => {
      if (window.innerWidth < 800) return
      if (!codeTutorialEl || codeTutorialEl.contains(e.target)) return
      if (!cards.some(el => el.contains(e.target))) {
        setActiveCard(null)
        setHighlights({})
      }
    }

    container.addEventListener("click", handleClickOutside)
    return () => container.removeEventListener("click", handleClickOutside)
  }, [area])

  return highlights
}

export default useScrollController
