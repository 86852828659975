import React, { useState, useEffect, useRef } from "react"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { createPortal } from "react-dom"

import { PureButton } from "uikit/Button"
import ModalMenu from "uikit/ModalMenu"

import DropdownIcon from "./dropdown.inline.svg"
import FileIcon from "./file.inline.svg"
import CloseIcon from "./close.inline.svg"
import * as S from "./styled"

const CodeTutorial = ({ isModal, isModalFilesSelect, files, highlights }) => {
  const [file, setFile] = useState(files[0])
  const [isOpen, setOpen] = useState(false)
  const [isFilesOpen, setFilesOpen] = useState(false)
  const isolate = useRef()

  const getHighlights = () => (file.name === highlights.file ? highlights.lines : null)

  useEffect(() => {
    if (!isModal) enableBodyScroll(isolate.current)
    else if (!isOpen) enableBodyScroll(isolate.current)
    else if (isFilesOpen) disableBodyScroll(isolate.current)
    else disableBodyScroll(isolate.current)

    const element = isolate.current
    return () => enableBodyScroll(element)
  }, [isModal, isOpen, isFilesOpen])

  useEffect(() => {
    const file = files.find(({ name }) => name === highlights.file) || files[0]
    if (highlights.file && isModal) setOpen(true)
    setFile(file)
  }, [isModal, highlights, files])

  const container = (
    <S.Container id="code-tutorial">
      <S.FilesWrap>
        {isModal && (
          <PureButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </PureButton>
        )}

        {isModalFilesSelect && (
          <>
            <ModalMenu
              onSelect={setFile}
              isOpen={isFilesOpen}
              onClose={() => setFilesOpen(false)}
              items={files}
            />
            <S.Files>
              <S.File isActive>{files.find(item => item.name === file.name)?.name}</S.File>
            </S.Files>
            <PureButton style={{ marginLeft: "auto" }} onClick={() => setFilesOpen(true)}>
              <DropdownIcon />
            </PureButton>
          </>
        )}

        {!isModalFilesSelect && (
          <S.Files>
            {files.map(item => (
              <S.File key={item.name} isActive={item.name === file.name} onClick={() => setFile(item)}>
                <FileIcon />
                {item.name}
              </S.File>
            ))}
          </S.Files>
        )}
      </S.FilesWrap>
      <S.Code ref={isolate} source={file.source} inline={file.content} highlights={getHighlights()} />
    </S.Container>
  )

  if (isModal && typeof document !== `undefined`) {
    return createPortal(<S.ModalContainer isOpen={isOpen}>{container}</S.ModalContainer>, document.body)
  }

  return container
}

CodeTutorial.defaultProps = {
  highlights: {},
  files: [],
}

export default CodeTutorial
