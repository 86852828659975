import React from "react"

import * as S from "./styled"
import ArrowIcon from "./assets/arrow-left.inline.svg"
import GitHubIcon from "./assets/github-icon.inline.svg"

const SAMPLES_URL = "https://github.com/dasha-samples"

const Navigation = ({ backUrl }) => {
  return (
    <S.Container>
      {backUrl && (
        <S.InnerLink to={backUrl}>
          <ArrowIcon />
          <span>Back</span>
        </S.InnerLink>
      )}
      <S.Link href={SAMPLES_URL} target="_blank" rel="noreferrer noopener">
        <span>More samples</span>
        <GitHubIcon />
      </S.Link>
    </S.Container>
  )
}

export default Navigation
